//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      constant,
      createBannerModal: {
        show: false,
        isEdit: false,
        data: {
          Position: '',
          Url: '',
          Icon: null,
        },
      },
    };
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadBannerOptionsAction(),
    ]);
    this.setGeneralLoader(false);
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('p2pBanners', {
      isBannersEnabled: 'isBannersEnabled',
      bannersList: 'banners',
    }),

    isConfirmCreateDisabled() {
      return Object.values(this.createBannerModal.data).some((value) => !value)
        || this.bannersList.some((banner) => {
          return banner.position === Number(this.createBannerModal.data.Position);
        });
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('p2pBanners', {
      loadBannerOptionsAction: 'loadBannerOptions',
      updateGlobalBannersOptionsAction: 'updateGlobalBannersOptions',
      createBannerOptionAction: 'createBannerOption',
      updateBannerOptionAction: 'updateBannerOption',
      deleteBannerOptionAction: 'deleteBannerOption',
    }),

    async enableBanners() {
      this.setGeneralLoader(true);
      await this.updateGlobalBannersOptionsAction(true);
      await this.loadBannerOptionsAction();
      this.setGeneralLoader(false);
      this.setSuccessNotification('Banners enabled');
    },

    async disableBanners() {
      this.setGeneralLoader(true);
      await this.updateGlobalBannersOptionsAction(false);
      await this.loadBannerOptionsAction();
      this.setGeneralLoader(false);
      this.setSuccessNotification('Banners disabled');
    },

    updateBanner(banner) {
      this.createBannerModal.data.Position = banner.position;
      this.createBannerModal.data.Url = banner.innerUrl;
      this.createBannerModal.data.Icon = null;
      this.createBannerModal.show = true;
      this.createBannerModal.isEdit = true;
    },

    async confirmUpdate() {
      const { Position, Url, Icon } = this.createBannerModal.data;
      const formData = new FormData();
      formData.append('Position', Position);
      formData.append('Url', Url);
      if (Icon) {
        formData.append('Icon', Icon, Icon.name);
      }

      this.setGeneralLoader(true);
      try {
        await this.updateBannerOptionAction(formData);
        await this.loadBannerOptionsAction();
        this.setSuccessNotification('Banner edit');
        this.cancelBannerModal();
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralLoader(false);
    },

    createBanner() {
      this.createBannerModal.show = true;
      this.createBannerModal.isEdit = false;
    },

    async confirmCreate() {
      const { Position, Url, Icon } = this.createBannerModal.data;
      const formData = new FormData();
      formData.append('Position', Position);
      formData.append('Url', Url);
      formData.append('Icon', Icon, Icon.name);

      this.setGeneralLoader(true);
      try {
        await this.createBannerOptionAction(formData);
        await this.loadBannerOptionsAction();
        this.setSuccessNotification('Banner created');
        this.cancelBannerModal();
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralLoader(false);
    },

    cancelBannerModal() {
      this.createBannerModal = {
        show: false,
        isEdit: false,
        data: {
          Position: '',
          Url: '',
          Icon: null,
        },
      };
    },

    deleteBanner(position) {
      this.confirmAction({
        title: this.$t('Are you sure you want to delete banner #?', { position }),
        callback: () => {
          this.confirmDelete(position);
        },
      });
    },

    async confirmDelete(position) {
      this.setGeneralLoader(true);
      await this.deleteBannerOptionAction(position);
      await this.loadBannerOptionsAction();
      this.setSuccessNotification('Banner deleted');
      this.setGeneralLoader(false);
    },
  },
};
